<template>
  <div class="payment-record-modal" v-show="isShow">
    <div class="payment-record">
      <div class="cl">
        <div class="fl title">{{$t('Payment Record')}}</div>
        <a class="fr btn-close" href="javascript:;" @click="isShow=false">
          <img src="../../../../assets/images/icon/close.png"/>
        </a>
      </div>
      <div class="table" style="overflow-x:auto">
        <table>
          <thead>
          <tr>
            <th>{{$t('Date')}}</th>
            <th>{{$t('Level')}}</th>
            <th>{{$t('Type')}}</th>
            <th>{{$t('AnnualFeePlan')}}</th><!--年費方案-->
            <th>{{$t('Expiration date')}}</th>
            <th>{{$t('total')}}</th>
            <th>{{$t('Payment method')}}</th>
            <th>{{$t('Payment refNo')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item of list" :key="item.id">
            <td>{{item.paymentTime|formatDate('yyyy/MM/dd')}}</td>
            <td>{{item.memberTypeName}}</td>
            <td>{{item.way==='renewal'?$t('Renewal'):$t('Upgrade')}}</td>
            <td>{{$t('SeveralYears',[item.priceYears])}}</td>
            <td>{{item.dealTime|formatDate('yyyy/MM/dd')}}</td>
            <td>HK${{totalPrice}}</td>
            <td>{{item.payPlatform|dictName('payPlatform')}}</td>
            <td>PayPal交易參考編號</td>
          </tr>
          </tbody>
        </table>
      </div>
      <Empty v-if="isEmpty" :title="$t('NoData')"/>
      <el-pagination class="pages txt-r"
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="changePage"
                     hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import Empty from '../../../../components/Empty';
  import {getMemberOrderList} from '../../../../api/admin-member';

  export default {
    name: 'PaymentRecord',
    components: {Empty},
    data() {
      return {
        isShow: false,
        isEmpty: false,
        list: [],
        totalPages: 1
      };
    },
    created() {
      this.getList();
    },
    methods: {
      show() {
        this.isShow = true;
      },
      // 获取帐号列表
      getList(page = 1) {
        const data = {page, perPage: 10};
        getMemberOrderList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getList(page);
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../../assets/style/table";
  .payment-record-modal{
    position:fixed;top:0;right:0;bottom:0;left:0;z-index:1800;text-align:center;background-color:rgba(0, 0, 0, 0.5);
    &:after{display:inline-block;height:100%;vertical-align:middle;content:'';}
  }
  .payment-record{
    display:inline-block;width:800px;padding:24px 30px;vertical-align:middle;background-color:#fff;animation-name:zoomIn;animation-duration:.4s;animation-fill-mode:backwards;
    .title{font-size:24px;}
    .btn-close{margin-top:6px;}
  }
  @keyframes zoomIn{
    0%{opacity:0;transform:scale(0)}
    50%{opacity:1;}
    100%{opacity:1;transform:scale(1)}
  }
</style>
