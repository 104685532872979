<template>
  <div class="main m-l">
    <div class="cl">
      <div class="fl h1">{{$t('Star Merchant Package')}}</div>
      <!--聯絡我們-->
      <i18n class="fr link" path="Inquire" tag="div">
        <router-link :to="{name:'About',params:{type:'contactus'}}" target="_blank">{{ $t('contactUs') }}</router-link>
      </i18n>
    </div>
    <!--<div class="subtitle">Under the hood, Cactus runs a small local web server for each website you're working on.</div>-->
    <div class="member-list">
      <member-list/>
    </div>
    <div class="payment-record">
      <a href="javascript:;" @click="showPaymentRecord">{{$t('Payment Record')}} &gt;</a>
    </div>
    <payment-record ref="paymentRecord"/>
  </div>
</template>
<script>
  import MemberList from './components/MemberList';
  import PaymentRecord from './components/PaymentRecord';

  export default {
    name: 'AdminMember',
    data() {
      return {};
    },
    components: {PaymentRecord, MemberList},
    created() {
    },
    methods: {
      // 显示支付记录
      showPaymentRecord() {
        this.$refs.paymentRecord.show();
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    min-height:800px;
    .h1{font-size:24px;}
    .link{
      line-height:36px;font-size:18px;
      a{text-decoration:underline;color:#ff6f61;}
    }
    .subtitle{margin-top:30px;text-align:center;font-size:16px;}
    .member-list{margin-top:60px;}
    .payment-record{
      position:absolute;right:0;bottom:0;left:0;padding-top:16px;text-align:right;border-top:1px solid #f4f4f4;
      a{color:#ff6f61;}
    }
  }
</style>
